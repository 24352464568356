<template>
    <defaultSec :title="'< 返回'" :returnState="true">
        <div>
            <el-form :model="form" label-width="120px">
                <el-form-item label="事件名称" prop="eventName" :required="true">
                    <el-input v-model="form.eventName" placeholder="事件名称"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="eventRemark" :required="true">
                    <el-input v-model="form.eventRemark" placeholder="请输入备注"></el-input>
                </el-form-item>
                <el-form-item label="事件表名" prop="eventTableName" :required="true">
                    <el-input v-model="form.eventTableName" :disabled="form.eventId>0" placeholder="请输入事件表名"></el-input>
                </el-form-item>
                <el-form-item label="数据库名" prop="eventDataBase"  :required="true">
                    <el-input v-model="form.eventDataBase" :disabled="form.eventId>0" placeholder="请输入数据库名"></el-input>
                </el-form-item>
                <el-form-item label="事件类型" prop="eventType" :required="true">
                    <el-select v-model="form.eventType" @change="changeEventType" filterable clearable
                               placeholder="事件类型">
                        <el-option v-for="item in eventTypeList"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="组名称" prop="eventGroupId" :required="true">
                    <el-select v-model="form.eventGroupId" filterable clearable placeholder="事件组">
                        <el-option v-for="item in eventGroupList"
                                   :key="item.eventGroupId"
                                   :label="item.eventGroupName"
                                   :value="item.eventGroupId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态" prop="status" :required="true">
                    <el-select v-model="form.status" filterable clearable placeholder="状态">
                        <el-option v-for="item in statusList"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="写模板列" prop="writeTemplate" :required="true" v-show="form.eventType==3">
                    <el-input v-model="form.writeTemplate" placeholder="请输入组备注"></el-input>
                </el-form-item>
                <el-form-item label="读模板列" prop="readTemplate" :required="true" v-show="form.eventType==1">
                    <el-input v-model="form.readTemplate" placeholder="请输入读模板列"></el-input>
                </el-form-item>
                <el-form-item label="显示模板列" prop="showTemplate" v-show="form.eventType==2">
                    <el-input v-model="form.showTemplate" placeholder="显示模板列 需要确认 写表中存在显示列的列名"></el-input>
                </el-form-item>
                <el-form-item label="排序" :required="true">
                    <el-input-number v-model="form.sort" :min="1" :max="1000" placeholder=""></el-input-number>
                </el-form-item>
                <el-form-item label="事件id" prop="eventId" v-show="false">
                    <el-input v-model="form.eventId" placeholder="请输入事件id"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button-group>
                        <el-button type="warning " size="mini"
                                   @click="addProperty(0)">添加属性
                        </el-button>
                        <el-button type="primary" @click="save" size="mini" class="header-btn">
                            保存
                        </el-button>
                    </el-button-group>
                </el-form-item>
            </el-form>
            <el-table :data="propertyList" border size="mini" v-show="showPropertyList">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column label="标识" prop="eventPropertyId" width="80"></el-table-column>
                <el-table-column label="属性名" prop="propertyName" width="190"></el-table-column>
                <el-table-column label="分类名" prop="propertyClassName" width="150"></el-table-column>
                <el-table-column label="数据类型" prop="properytDataType" width="150"></el-table-column>
                <el-table-column label="字典" prop="dicType" width="130">
                    <template slot-scope="scope">
                        {{scope.row.dicType == 1 ? '支持' : '不支持'}}
                    </template>
                </el-table-column>
                <el-table-column label="排序" prop="sort" width="130"></el-table-column>
                <el-table-column label="状态" prop="statusName"></el-table-column>
                <!--<el-table-column label="创建时间" prop="createTime">-->
                <!--</el-table-column>-->
                <el-table-column label="操作" fixed="right" width="140">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="primary " size="mini" v-show="scope.row.dicType == 1"
                                       @click="addDic(scope.row.eventPropertyId)">添加字典
                            </el-button>
                            <el-button type="success " size="mini" v-show="scope.row.dicType == 1"
                                       @click="reflashOptionItem(scope.row.eventPropertyId)">更新字典项
                            </el-button>
                            <el-button type="warning " size="mini"
                                       @click="addProperty(scope.row.eventPropertyId)">详情
                            </el-button>
                            <el-button type="danger " size="mini"
                                       @click="deleteEventProerty(scope.row.eventPropertyId)">删除

                            </el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>

            <el-dialog title="添加事件属性" :visible.sync="showDialog" center>
                <el-form :model="eventPorperty" label-width="120px" v-show="false">
                    <el-form-item label="事件属性id" prop="eventPropertyId" v-show="false">
                        <el-input v-model="eventPorperty.eventPropertyId" placeholder="请输入属性id"></el-input>
                    </el-form-item>
                    <el-form-item label="事件id" prop="eventId" v-show="false">
                        <el-input v-model="eventPorperty.eventId" placeholder="事件id"></el-input>
                    </el-form-item>
                    <el-form-item label="请选择属性" prop="propertyId" :required="true">
                        <el-select v-model="eventPorperty.propertyId" filterable clearable placeholder="属性">
                            <el-option v-for="item in allPropertyList"
                                       :key="item.value"
                                       :label="item.name"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="连接属性" prop="eventPropertyType" :required="true">
                        <el-select v-model="eventPorperty.eventPropertyType" filterable clearable placeholder="连接属性">
                            <el-option v-for="item in eventPropertyTypeList"
                                       :key="item.value"
                                       :label="item.name"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="字典" prop="dicType" :required="true">
                        <el-select v-model="eventPorperty.dicType" filterable clearable placeholder="字典">
                            <el-option v-for="item in dicTypeList"
                                       :key="item.value"
                                       :label="item.name"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="排序" prop="sort" :required="true">
                        <el-input-number v-model="eventPorperty.sort" :min="1" :max="1000"
                                         placeholder=""></el-input-number>
                    </el-form-item>
                    <el-form-item>
                        <el-button-group>
                            <el-button type="primary" @click="saveEventProperty(eventPorperty)" size="mini" class="header-btn">
                                保存
                            </el-button>
                        </el-button-group>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    import ElDialog from "../../../../node_modules/element-ui/packages/dialog/src/component";
    export default {
        data() {
            return {
                formParam: {
                    pageSize: 15,
                    currentPage: 1,
                    eventName: '',
                    eventType: '',
                    status: '',
                    eventTableName: '',
                },
                eventGroupList: [],
                eventTypeList: [],
                propertyList: [],
                allPropertyList:[],
                eventPropertyTypeList: [],
                showDialog: false,
                statusList: [{id: 0, name: '禁用'}, {id: 1, name: '启用'}],
                showPropertyList: false,
                dataList: [],
                dicTypeList: [],
                form: {
                    eventId: this.$route.params.eventId || 0,
                    eventGroupId: '',
                    eventName: '',
                    eventTableName: '',
                    eventDataBase: '',
                    eventType: 2,
                    eventImg: '',
                    writeTemplate: '',
                    readTemplate: '',
                    eventRemark: '',
                    showTemplate:'',
                    status:1,
                    sort: 1
                },
                eventPorperty: {
                    eventPropertyId: '',
                    eventId: '',
                    propertyId: '',
                    remark: '',
                    dicType: 0,
                    dicDataType: 2,
                    eventPropertyType: 0,
                    status: 1,
                    sort: 1
                },
                total: 0
            }
        },
        components: {
            ElDialog, defaultSec, tablePagination
        },
        mounted(){
            this.showPropertyList = this.form.eventId > 0
            this.initEventGroupList()
            this.initEventTypeList()
            this.initPropertyList()
            this.initDicTypeList()
            this.initPropertyList()
            if (this.form.eventId > 0) {
                this.getDetail()
                this.getEventPropertyList()
            }
        },
        methods: {
            initEventGroupList(){
                this.$apiGet('lechun-cms/event/getEventGroupList', this.formParam).then(res => {
                    console.log(res)
                    this.eventGroupList = res.list
                })
            },
            initEventTypeList(){
                this.$apiGet('lechun-cms/event/getEventTypeList', {}).then(res => {
                    console.log(res)
                    this.eventTypeList = res
                })
            },
            initEventPropertyTypeList(){
                this.$apiGet('lechun-cms/scrmproperty/getEventPropertyTypeList', {}).then(res => {
                    console.log(res)
                    this.eventPropertyTypeList = res
                })
            },
            initDicTypeList(){
                this.$apiGet('lechun-cms/scrmproperty/getDicTypeList', {}).then(res => {
                    console.log(res)
                    this.dicTypeList = res
                })
            },
            reflashOptionItem(eventPropertyId){
                this.$apiGet('lechun-cms/scrmOption/buildDictionary', {eventPropertyId:eventPropertyId}).then(res => {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                })
            },
            initPropertyList() {
                this.allPropertyList = []
                this.$apiGet('lechun-cms/scrmproperty/getPropertyList', {propertyType: this.form.eventType == 1 || this.form.eventType == 3 ? 1 : 2}).then(res => {
                    console.log(res)
                    this.allPropertyList = res
                })
            },
            changeEventType(){
                this.initPropertyList()
            },
            addDic(id){
                this.$router.push({
                    path: '/optionDetail/:id',
                    name: 'optionDetail',
                    params: {id: id }
                });
            },
            addProperty(eventPropertyId){
                this.$router.push({
                    path: '/eventProperty/:eventPropertyId/:eventId',
                    name: 'eventProperty',
                    params: {eventPropertyId: eventPropertyId,eventId: this.form.eventId }
                });
            },
            getDetail() {
                this.$apiGet('lechun-cms/event/getEvent', {eventId: this.form.eventId}).then(res => {
                    this.form = res
                    this.initPropertyList()
                })
            },
            save(){
                if (this.form.eventGroupId == '') {
                    this.$message({
                        showClose: true,
                        message: '事件分组必选',
                        type: 'error'
                    });
                    return;
                }
                if (this.form.eventTableName == '') {
                    this.$message({
                        showClose: true,
                        message: '表名称名称必填',
                        type: 'error'
                    });
                    return;
                }
                if ((this.form.eventTableName.toUpperCase().indexOf("JOIN")==-1&&this.form.eventTableName.toUpperCase().indexOf("WHERE")==-1)&&this.form.eventDataBase == '') {
                    this.$message({
                        showClose: true,
                        message: '数据库名称必填',
                        type: 'error'
                    });
                    return;
                }
                if (this.form.eventName == '') {
                    this.$message({
                        showClose: true,
                        message: '事件名称必填',
                        type: 'error'
                    });
                    return;
                }
                if (this.form.eventType == 1 && this.form.readTemplate == '') {
                    this.$message({
                        showClose: true,
                        message: '读模板必填',
                        type: 'error'
                    });
                    return;
                }
                if (this.form.eventType == 3 && this.form.writeTemplate == '') {
                    this.$message({
                        showClose: true,
                        message: '写模板必填',
                        type: 'error'
                    });
                    return;
                }
                if (this.form.eventId == 0) {
                    this.form.eventId = ''
                }
                this.$apiGet('lechun-cms/event/saveEvent', this.form).then(res => {
                    console.log(res)
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.$router.push({path: '/event', name: 'event'})
                })
            },
            getEventPropertyList(){
                this.propertyList = []
                this.$apiGet('lechun-cms/scrmproperty/getEventPropertyList', {eventId: this.form.eventId}).then(res => {
                    console.log(res)
                    this.propertyList = res
                })
            },
            saveEventProperty(row){
                this.$apiGet('lechun-cms/scrmproperty/saveEventProperty', row).then(res => {
                    console.log(res)
                    if (res.status == 200) {
                        this.showDialog=false
                        this.getEventPropertyList()
                    }
                })
            },
            deleteEventProerty(eventPropertyId){
                this.$confirm('删除后不可撤销，确定删除吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-cms/scrmproperty/deleteEventProperty', {eventPropertyId: eventPropertyId}).then(res => {
                        console.log(res)
                        this.getEventPropertyList()
                    })
                }).catch(() => {

                });
            }
        }
    }
</script>


<style scoped>
    .expand-title {
        width: 70px;
        color: #444548;
        line-height: 30px;
        text-align: right;
        margin-right: 10px;
        font-weight: 600;
    }

    .expand-flex {
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
</style>